import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ProductCounterInterface } from 'interfaces/Product/ProductItem';
import cn from 'classnames';
import { useValidProductId } from 'helpers/hooks/validProductId';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'stores/store';
import { IUserStore } from 'stores/userStore';
import { useRouter } from 'next/router';
import { handleChangeCart } from 'services/cartService';
import { setOrderData } from 'stores/orderStore';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import styles from './ProductCounter.module.scss';

interface ProductCounter {
  productData?: ProductCounterInterface;
  initialCount?: number;
  successCallback?: any;
  errorCallback?: any;
  addStyles?: string[];
  maxValue?: number;
}

const ProductCounter: React.FC<ProductCounter> = ({
  productData,
  initialCount = 1,
  successCallback,
  errorCallback,
  addStyles = [],
  maxValue = 1000,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { locale: currentLanguage } = router as {
    locale: string;
  };
  const { t } = useTranslation('products');

  const userDataStored = useSelector<RootState, IUserStore>(
    state => state.user
  );
  const geoDataStored = userDataStored.geoData;

  const { setModalType, setModalSelectData } = useContext(AppContext);

  const [quantity, setQuantity] = useState(initialCount);

  const modalData = {
    title: t('products.delete_modal.delete_title'),
    subTitle: t('products.delete_modal.delete_text'),
    buttonCloseText: t('products.delete_modal.cancel'),
    buttonActionText: t('products.delete_modal.delete_button'),
    buttonActionEffect: () => changePrice(0, true),
  };

  const changePrice = useCallback(
    async (quantity, isModal = false) => {
      if (quantity === 0 && !isModal) {
        setModalSelectData(modalData);
        setModalType(MODAL.selection);
        return;
      }

      const currentId = useValidProductId(
        process.env.NEXT_PUBLIC_API_HOST,
        productData
      );

      handleChangeCart({
        id: currentId,
        quantity: quantity,
        language: currentLanguage,
        geo: geoDataStored,
        successCallback: data => {
          dispatch(setOrderData(data));

          if (successCallback) {
            successCallback(data);
          }
        },
        errorCallback: errorCallback,
      });
    },
    [productData, geoDataStored, currentLanguage]
  );

  const handleQuantityInput = e => {
    setQuantity(e.target.value);

    if (e.target.value > 0 && e.target.value <= maxValue) {
      changePrice(e.target.value);
    }
  };

  const handleQuantityInputBlur = () => {
    let currentInput = Math.round(quantity);

    if (currentInput <= 0) {
      currentInput = 1;
    }

    if (currentInput > maxValue) {
      currentInput = maxValue;
    }

    setQuantity(currentInput);
    changePrice(currentInput);
  };

  useEffect(() => {
    setQuantity(initialCount);
  }, [initialCount]);

  return (
    <div className={cn(styles.productCounter__quantity, ...addStyles)}>
      <button
        className={styles.productCounter__quantityButton}
        disabled={quantity === 0}
        onClick={() => {
          if (quantity > 1) {
            setQuantity(quantity - 1);
          }

          changePrice(quantity - 1);
        }}
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M11 11H13H19V13H13H11H5V11H11Z' />
        </svg>
      </button>
      <input
        className={styles.productCounter__quantityInput}
        type='number'
        value={quantity}
        onChange={handleQuantityInput}
        onBlur={handleQuantityInputBlur}
      />
      <button
        className={styles.productCounter__quantityButton}
        disabled={quantity === maxValue}
        onClick={() => {
          setQuantity(quantity + 1);
          changePrice(quantity + 1);
        }}
      >
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z' />
        </svg>
      </button>
    </div>
  );
};

export default ProductCounter;
