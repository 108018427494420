import React from 'react';

const CartButton: React.FC = () => {
  return (
    <svg
      width='24'
      height='24'
      xmlns='http://www.w3.org/2000/svg'
      dangerouslySetInnerHTML={{
        __html: `
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2 2h3.846l.504 3h14.67l-2 10H6.004L4.154 4H2V2Zm4.687 5 1.009 6h9.684l1.2-6H6.687ZM8 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM17 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
      />
    `,
      }}
    />
  );
};

export default CartButton;
